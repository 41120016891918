.mainFrame {
  margin-top: -30rem;
  transition-property: all;
  transition-duration: 350ms;
  width: 464px;
  background: white;
  border-radius: var(--border-radius-sm);
  left: 0;
  top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 28px;
  outline: none;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 27px;
}

.titleCont {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: var(--dark-color-source);
  padding-bottom: 16px;
}

.form {
  width: 100%;
}

.fieldContainer {
  width: 100%;
}

.fieldLabel {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: var(--dark-color-source);
}

.fieldLabel {
  display: inline-block;
}

.inputElem {
  width: 100%;
  border-radius: var(--border-radius-md);
  outline: none;
  border: solid lightgray 1px;
  background: #fff;
  padding: 10px 11px;
  color: var(--dark-color);
  font-weight: 500;
  height: 36px;
}

.inputElem::placeholder {
  font-size: 14px;
  line-height: 110%;
  font-weight: 500;
}

.inputExample {
  font-weight: 300;
  font-size: 14px;
  line-height: 110%;
  color: var(--dark-color-example);
  word-break: break-all;
}

.inputElemNotValid {
  border: solid 1px var(--danger-color);
}

.buttons {
  padding: .3rem 0;
  width: 48%;
  margin: 0 10px;

  & span {
    font-wight: 500;
  }
}

.cancelButton {
  background: #E9E9E9;

  & span {
    color: var(--dark-color);
  }

  &:active {
    background: #cbcbcb;
  }
}

.addButton {
  background: #103451;

  & span {
    color: var(--button-text-color);
  }

  &:active {
    background: #103451;
  }
}

.showFrame {
  margin-top: 5rem;
}

.disabledButton {
  background: lightgray;

  & span {
    color: white;
  }

  &:active {
    background: lightgray;
    box-shadow: none;
  }
}

.errorMessage {
  color: var(--danger-color);
  font-weight: 300;
  font-size: 14px;
  line-height: 110%;
  display: block;
  padding-top: 5px;
}

.errorScreen {
  color: var(--danger-color);
  font-size: 14px;
  text-align: center;
  padding-top: 20px;
}

.contLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
