.container {
  width: 100%;
  background: #f3f5f7;
  border-radius: var(--border-radius-sm);
  margin-bottom: .7rem;
  padding: .5rem;
}

.screen {
  width: 150px;
  background: #f9f9f9;
  border: solid 1px transparent;

  &::after{
    content: '';
    display: block;
    margin-top: 65%;
  }
}
