.sPFMiddleBlock {
  padding: 0 .3rem;
  padding-top: .5rem;
}

.sPFTitle {
  font-weight: 700;
}

.sectionItem {
  color: var(--dark-color);
  margin-top: .1rem;
  border-radius: 6px;
  padding: .2rem .5rem;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background: #EEF1F3;
  }
}

.chosenSection {
  background: #EEF1F3;
  color: #1CA5E0;
}

.divider {
  border-top: #f3f3f3 solid 1px;
  padding-bottom: .5rem;
  margin: 0 -.7rem;
}
