.mainPageFooterCont {
  background: var(--secondary-color);
  justify-content: center;
  user-select: none;
  margin-top: auto;
}

.mainPageFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.mainPageFooterLogo {
  text-decoration: none;
  font-size: 2rem;
  color: var(--dark-color);
  font-weight: 800;
  padding-left: 1rem;
}

.mainPageFooterCpr {
  font-size: .85rem;
  color: var(--dark-color);
  opacity: .4;
  padding: 0 1rem;
  text-align: center;
}
