
.badgeItemContainer {
  position: relative;
  background: var(--dark-color);
  border-radius: 4px;
  margin-right: 1px;
  margin-bottom: 1px;

  display: flex;
  align-items: center;
  line-height: 1rem;

  &:hover .tooltipCont {
    display: block;
  }
}

.badgeItemIconCont {
  padding: 4px 4px;

  & > img {
    display: block;
    width: 18px;
    height: 18px;
  }

}

.badgeText {
  color: white;
  font-weight: 600;
  font-size: .6rem;
  padding: 2px;
  padding-right: 4px;

}

.tooltipCont {
  position: absolute;
  bottom: calc(100% - 5px);
  display: none;
  z-index: 2;

  &::after {
    content: '';
    display: block;
    width: 10px;
    margin-left: 6px;
    border: 5px solid transparent;
    border-top: 5px solid black;
  }
}

.tooltipText {
  background: black;
  color: white;
  padding: 6px 8px;
  border-radius: 6px;
  &:hover {
    display: none;
  }
}


.infoTypeStyle {
  margin-left: .5rem;
  background: transparent;

  & .badgeItemIconCont, .badgeText {
    padding: 0;
  }

}

.infoTypeTooltip {

  left: -90%;

  &::after {
    margin-left: 30%;
  }
}

.infoTypeTooltipSm {
  left: -100px;
  &::after {
    margin-left: 100px;
  }
}
