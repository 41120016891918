a {
  text-decoration: none;
}


.statisticJson {
  white-space: break-spaces;
}

.postItemCont {
  display: flex;
  padding: .5rem;
  border-radius: var(--border-radius-sm);
  user-select: none;
  cursor: pointer;
  background: #F3F5F7;
  text-decoration: none;
  color: var(--dark-color);

  &.withDebug {
    user-select: initial;
  }
}

.shareCount {
  color: #ccc;
  font-style: italic;
}

.postItemRightCont {
  padding: 1rem;
  min-width: 150px;
  width: 100%;
}

.postItemFrameCont {
  width: 150px;
  background: #f3f5f7;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &:after {
    content: '';
    display: block;
    margin-top: 70%;
  }
}

.imgStyle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.postItemText {

  & > a > div {
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--dark-color);
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  display: none;
}

.sWBLikeButton {
  visibility: hidden;
  font-weight: 500;
  background: #000;
  opacity: .7;
  border-radius: var(--border-radius-sm);
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  display: flex;
  align-items: center;
  font-size: .7rem;
  color: #fff;
  line-height: 1;

  & img {
    padding-right: 7px;
  }
}

.trendsStyle {
  display: none;
  color: #1da1f2;
  font-size: .82rem;
}

.tags {
  border-top: 1px dashed #cdcdcd;
  margin-top: 5px;
  padding-top: 5px;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  font-size: .9rem;
  color: #8dabc5;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.displayed {
  display: block;
}

.commentButton {
  right: auto;
  left: 8px;
}

