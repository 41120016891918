.commentItem {
  margin-bottom: 15px;
}

.commentItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.commentItemAuthor {
  display: flex;
  font-size: 14px;
}

.commentItemAuthorAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 14px;
  background-color: var(--dark-color);
}

.commentItemDate {
  color: #9B9B9B;
  font-size: 12px;
}

.commentItemBody {
  font-size: 14px;
  line-height: 18px;
  background: #F3F5F7;
  padding: 12px;
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(16, 70, 101, 0.1);
}