.sWCont {
  margin: 1rem 0;
}

.sWPart {
  height: 100%;
  align-content: space-between;
}

.sWTopPanel {
  display: flex;
  align-items: center;
  padding: .75rem 1.5rem;
  background: #f6f7f8;
  border-radius: var(--border-radius-sm);
  position: relative;
  user-select: none;
}

.sWFilterButton {
  margin-left: auto;
  padding: 1rem 1.8rem;
  font-size: .9rem;
  line-height: 0;
}

.sWSocialName {
  font-size: 1.5rem;
  color: var(--facebook-color);
  line-height: 1;
  margin-left: 6px;
}

.sWBottomPanel {
  display: flex;
  align-items: center;
  user-select: none;
}

.sWSeeAllLink {
  margin: 0 2rem;
  text-decoration: none;
  color: var(--primary-color);
  flex-shrink: 0;
}

.sWMBlock {
  padding: 1rem 1rem 1.4rem 1rem;
}

.sWDivider {
  border-bottom: 1px solid #383838;
  width: 100%;
  opacity: .1;
}

.sWFilter {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #fff;
  border-radius: var(--border-radius-sm);
}


.hidden {
  display: none !important;
}

.empty {
  display: none;
}

.sWStatusCont {
  font-weight: 700;
  color: lightgray;
  margin-left: 1rem;
  font-size: 1rem
}

.sWParserUpdaterCont {
  margin-left: auto;
}

.leftImgLink {
  display: flex;
  text-decoration: none;
  > img {
    height: 20px;
  }
}

.toLeft {
  margin-left: auto;
}


.simpleTooltip {
  position: absolute;
  bottom: calc(100% - 5px);
  display: none;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    width: 10px;
    margin-left: 15px;
    border: 5px solid transparent;
    border-top: 5px solid black;
  }
}

.leftImgLink:hover .simpleTooltip{
  display: block;
}

.simpleTooltipText {
  background: black;
  color: white;
  padding: 6px 8px;
  border-radius: 6px;
}
