.ppcCont {
  border: 1px solid rgba(16, 70, 101, 0.1);
  border-radius: var(--border-radius-sm);
  background: #F3F5F7;
  line-height: 1rem;
  flex-shrink: 0;
  min-width: 8.5rem;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 3rem;
  outline: none;
}

.ppcInnerButton {
  cursor: pointer;
  line-height: 0;
  padding: .7rem 1rem;
  outline: none;
}

.ppcMiddlePart {
  margin: 0 .5rem;
  line-height: 1;
}

.ppcMiddlePart1 {
  font-weight: 500;
  font-size: 1.5rem;
}

.ppcMiddlePart2 {
  font-size: 1.2rem;
  color: #939191;
}

.ppcMiddlePart3 {
  color: #939191;
}

