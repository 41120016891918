.badgeContainer {
  display: none;
  flex-wrap: wrap;
  margin-bottom: 8px;
  position: relative;
}

.badgeContainerShow {
  display: flex;
}

.authorAndDateContainer {
  display: none;
  flex-wrap: wrap;
  font-size: .8rem;
}

.nameDateShow{
  display: flex;
}

.authorCont {
  color: var(--dark-color);
  flex-shrink: 0;
  position: relative;
  max-width: 60%;
}
.dateCont {
  flex-shrink: 0;
  margin-left: auto;
  color: lightgray;
  padding-left: 3px;
  position: relative;
}

.smallAuthorAndDateContainer{
  align-items: center;
  border-radius: 4px;
  padding: 0 4px;
  background: #8dabc5;
}

.simpleTooltip {
  position: absolute;
  bottom: calc(100% - 5px);
  display: none;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    width: 10px;
    margin-left: 15px;
    border: 5px solid transparent;
    border-top: 5px solid black;
  }
}

.simpleTooltipText {
  background: black;
  color: white;
  padding: 6px 8px;
  border-radius: 6px;
}

.authorCont:hover .simpleTooltip{
  display: block;
}

.dateCont:hover .simpleTooltip{
  display: block;
}

.infoCont {
  margin-left: auto;
  display: flex;
  align-items: center;
}


.lastTooltip{
  left: -30px;

  &::after {
    margin-left: 50%;
  }
}

.authorLinkStyle {
  display: none;
  & a{
    color: orange;
    font-size: .8rem;
  }
}

.authorLinkStyleShow {
 display: block;
}

.sourceHostStyleShow {
  display: block !important;
}

.sourceHostStyle {
  display: none;
  & a{
    color: #1CA5E0;
    font-size: .8rem;
  }
}
