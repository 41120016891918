.mainFrame {
  margin-top: -30rem;
  opacity: 0;
  transition-property: all;
  transition-duration: 350ms;
  width: 400px;
  background: white;
  border-radius: var(--border-radius-sm);
  left: 0;
  top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0rem;
  outline: none;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.titleCont {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: var(--dark-color);
  padding-bottom: .5rem;
}

.templateNameCont {
  width: 100%;
  padding-bottom: 1rem;
  text-align: center;
  color: #1CA5E0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputElem {
  width: 100%;
  border-radius: var(--border-radius-sm);
  outline: none;
  border: solid lightgray 1px;
  background: #F3F5F7;
  padding: .6rem 1rem;
  color: var(--dark-color);
  font-weight: 500;
}

.buttons {
  padding: .3rem 0;
  width: 5rem;
  margin: .3rem;

  & span {
    font-weight: 500;
  }
}

.cancelButton {
  background: #F3F5F7;

  & span {
    color: var(--dark-color);
  }

  &:active {
    background: #dadada;
  }
}

.showFrame {
  margin-top: 5rem;
  opacity: 1;
}

.disabledButton {
  background: lightgray !important;
  padding: .3rem 1rem;

  & span {
    color: white;
  }

  &:active {
    background: lightgray;
    box-shadow: none;
  }
}

.screenHide {
  display: none;
}

.errorScreen {
  color: orangered;
  font-size: .9rem;
  padding-bottom: 1.5rem;
  text-align: center;
}
.nameNotVisible {
  visibility: hidden;
}
