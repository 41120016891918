.innerBlock {
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemStyle {
  width: 100%;
  border-radius: 6px;
  flex-grow: 0;
  background: #f3f5f7;
  color: #104665;
  font-size: .9rem;
  user-select: none;
  padding: .5rem;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s;

  &:hover {
    background: #eeeeee;

    .itemDeleteStyle {
      opacity: 1;
    }
  }
}

.itemForceBackground {
  background: #f3f5f7 !important;
}

.itemStyleWrapper {
  align-items: center;
  display: flex;
}

.moreVerticalStyle {
  padding-right: .5rem;
  display: flex;
  align-items: center;

  &>img{
    width: 16px;
    height: 16px;
  }
}

.parserIconContainer {
  width: 23px;
  padding-right: .3rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > img {
    width: 100%;
  }
}

.itemDeleteStyle {
  height: 11px;
  padding: 0;
  margin-left: auto;
  outline: none;
  opacity: 0;
}

.highlight{
  background: #1CA5E0;
}
