.sortingPostsFilterCont {
  margin-left:auto;
  position: relative;
  font-size: .85rem;
  color: var(--dark-color);

}

.sPFBlock {
  position: absolute;
  right: 0;
  top: 2.7rem;
  width: 190px;
  background: white;
  border-radius: var(--border-radius-sm);
  z-index: 1;
  display: none;
  flex-direction: column;
  padding: 1rem .5rem;
}

.sPFTopPart {
  padding: 0 .3rem;
}

.sPFTopPartTitle {
  font-weight: 700;
}

.sPFBottomPart {
  padding: 0 .3rem;
}

.sPFBottomPartTitle {
  font-weight: 700;
  padding-top: 1rem;
}

.sPFBottomPartContent {
  display: flex;
  flex-wrap: wrap;
}

.sPFBottomPartApplyButton {
  padding: 0 .3rem;
  padding-top: 1rem;
}

.sPFAdditionalStyleForApplyButton {
  width: 100%;
  padding: .3rem 0;
}

.sPFMainButton {
  background: #1CA5E0;
  padding: 4px 1.75rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  color: white;
  font-weight: 500;
  position: relative;
  outline: none;
}

.hidden {
  display:flex;
  z-index: 1001;
}

.highLayer {
  z-index: 1001;
}

.appear {
  display: none;
}

.divider {
  border-top: #f3f3f3 solid 1px;
  margin: 0 -.7rem;
  margin-top: .8rem;
}
