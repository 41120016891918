.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.imgWrapper {
  width: 100%;
  height: 100%;
}

.invisible {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
