body {
  --primary-color: #1CA5E0;
  --primary-color-darken: #069AD9;
  --primary-color-lighten: #c5def3;
  --primary-light-gradient-color: linear-gradient(0deg, rgba(29, 161, 242, 0.1), rgba(29, 161, 242, 0.1));


  --dark-color: #104565;
  --dark-color-source: #1B3E5A;
  --dark-color-darken: #04334E;
  --dark-color-lighten: #b1d0d7;
  --dark-color-example: #7C8B97;

  --light-color: #e8e8e8;

  --secondary-color: #EEF1F3;
  --secondary-color-darken: #d9dee3;
  --secondary-color-lighten: #ebebee;

  --color-lightgray: #EEF1F3;
  --border-radius-sm: 6px;
  --border-radius-md: 8px;

  --font-color-basic: #383838;
  --danger-color: #FF6161;

  --button-text-color: white;
  --white-text-color: white;
  --dark-text-color: #103451;
  --white-background-color: white;
  --transparent-light-white: rgba(255, 255, 255, 0.1);
  --hover-light-color: #EfF0F2;

  --facebook-color: #008CE0;
  --source-tag-color: #3A3A3A;;
  --source-tag-background: #EEEFF2;
  --source-tag-background-selected: #d2d4d5;

  --mobile-responsive: 720px;
}

