.itemsContainerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .4rem;
}

.containerTitle {
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.clickableStr {
  text-decoration: underline;
  cursor: pointer;
  color: #104665;
  font-weight: 500;
  font-size: .85rem;
  padding-right: 1rem;
  outline: none;
}

.borderContainer {
  display: flex;
  flex-direction: column;
  border: solid #e6e6e6 1px;
  border-radius: 6px;
  background: white;
}

.titleStyle {
  padding-left: 1rem;
  font-size: 1rem;
  color: #104665;
  font-weight: 500;
}

.refreshButStyle {
  width: 85%;
  margin-top: .5rem;
  padding: .3rem;
  user-select: none;
}

.refreshButStyleDisabled {
  width: 85%;
  margin-top: .5rem;
  padding: .3rem;
  background: lightgray;
  user-select: none;

  &:active {
    background: lightgray;
    box-shadow: none;
  }
}

.addSourceButton {
  margin-left: auto;
  margin-right: 15px;

  text-decoration: underline;
  background: none;
  cursor: pointer;
  color: #104665;
  font-weight: 500;
  font-size: .85rem;
  outline: none;

  &:active {
    background: none;
    box-shadow: none;
  }
}

.buttonText {
  color: #104665;
  margin-top: -5px;
}
