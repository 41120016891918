.button {
  flex-shrink: 0;
  background: var(--primary-color);
  border-radius: var(--border-radius-sm);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:active {
    background: var(--primary-color-darken);
    box-shadow: 0 0 0 3px var(--primary-color-lighten);
  }
}

.buttonText {
  font-weight: 500;
  font-family: 'Mukta';
  color: #fff;
}

