.commentNotification {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  width: 100%;
  color: var(--font-color-basic);
  font-size: 14px;
  line-height: 18px;
}

.notificationContent {
  display: block;
  background: none;
  border: 0;
  padding: 16px;
  width: 100%;
}

.postContent {
  background: #F3F4F6;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-sm);
  padding: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--font-color-basic);

  :global {
    img {
      width: 64px;
      height: 48px;
      object-fit: cover;
      margin-right: 8px;
    }
  }
}

.postContentText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 500;
}

.notificationComment {
  margin-top: 8px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 17px;
  font-size: 18px;
  color: #88A3B2;
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-lightgray);
  }
}