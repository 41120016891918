.filterBarRow {
  justify-content: center;
  padding-top: 1rem;
  max-height: 800px;
}

.filterBarCont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  &>span{
    line-height: 2.4rem;
    color: var(--dark-color);
    font-size: .85rem;
    font-weight: 500;
  }
}

.filterBarBadgesCont {


  position: -webkit-sticky;
  position: sticky;
  top: 20px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  &>span{
    line-height: 2.4rem;
    color: var(--dark-color);
    font-size: .85rem;
    font-weight: 500;
  }
}

.filterBarContNotShow {
  display: none;
}

.templatesBlock {
  padding: 2rem;
  background: #eef1f3;
  z-index: 2;
  display: flex;
  justify-content: center;

}

.widgWrapCont {
  background: #eef1f3;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}

@media screen and (max-width: 948px){
  .filterBarRow {
    padding-top: 0;
  }
}
