.notificationAuthor {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.notificationAuthorAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--dark-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  :global {
    img {
      width: 100%;
    }
  }
}