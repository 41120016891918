.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wheel {
  animation: spin 1.5s linear infinite;
  width: 15px;
  height: 15px;
  box-shadow: -20px 0 0 #1CA5E0, 20px 0 0 #1CA5E0,0 20px 0 #1CA5E0, 0 -20px 0 #1CA5E0,;
  border-radius: 1000rem;
  background: transparent;
}

.text {
  font-size: 20px;
  font-weight: 700;
  padding-top: 2rem;
  color: #1CA5E0;
}

.hidden {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

