.updaterComp {
  color: #8d8d8d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: .5rem;
}

.updateBtn{
  cursor: pointer;
  outline:none;
  display: flex;
  align-items: center;
  margin-left: .5rem;
}

.updatedTime {
 color: rgb(16, 70, 101)
}

.rotateUpdater {
  animation: rotateUpdaterAnimation 1s ease-in-out infinite;
}

.updBlock {
  font-size: .9rem;
  line-height: 1;
}


@keyframes rotateUpdaterAnimation {
  100% {
    transform: rotate(360deg);
  }
}

