.container {
  background: #EEF1F3;
  font-size: .85rem;
  color: var(--dark-color);
  outline: none;
  border-radius: 1000rem;
  padding: .2rem .75rem;

  cursor: pointer;
  margin: .2rem .3rem;
  margin-left: 0;

}

.checked {
  background: var(--dark-color);
  color: white;
}
