.postItemCont {
  display: flex;
  padding: .5rem;
  border-radius: var(--border-radius-sm);
  user-select: none;
  cursor: pointer;
  background: #F3F5F7;
  text-decoration: none;
  color: var(--dark-color);
  border-left: 4px solid #1da1f2;
  align-items: center;
}

.postItemText {
  height: 23px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 1rem;
  margin-right: auto;
  align-self: center;
}

.categoryBlock {
  min-width: 8%;
  max-width: 25%;
  text-align: center;

  & > a > div {
    color: #1CA5E0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: .85rem;
  }
}

.catogoryBlockHide {
  display: none;
}

.postItemRightCont {
  text-decoration: none;
  color: var(--dark-color);
  width: 100%;
  display: flex;
}

.badgesPart {
  flex-shrink: 0;
  margin-top: 8px;
}

.commentButton {
  position: static;
}