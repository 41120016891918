.widgetMainContainer {
  padding-right: 2rem;
}

.itemsContainerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .4rem;
}

.containerTitle {
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.borderContainer {
  display: flex;
  flex-direction: column;
  border: solid #e6e6e6 1px;
  border-radius: 6px;
  background: white;
}

.titleStyle {
  padding-left: 1rem;
  font-size: 1rem;
  color: #104665;
  font-weight: 500;
}

.listContainer {
  padding-left: .1rem;
  padding-top: 2.5rem;
  padding-right: .2rem;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
  height: 405px;
  width: 250px;
  cursor: pointer;
}
