.sMiddle {
  padding: 1rem 1rem 1.4rem 1rem;
}

.sTopPanel {
  display: flex;
  align-items: center;
  padding: .75rem 1.5rem;
  background: #f6f7f8;
  border-radius: var(--border-radius-sm);
  position: relative;
  user-select: none;
}

.sFilterButton {
  margin-left: auto;
  padding: 1rem 1.8rem;
  font-size: .9rem;
  line-height: 0;
  visibility: hidden;
}

.sPaginBut {
  margin-left: auto;
  width: 8.5rem;
}
