.container {
  outline: none;
  width: 100%;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: .3rem .8rem;
  margin: .1rem 0rem;

  & img {
    width: 14px;
    height: 14px;
    display: block;
    filter: grayscale(100%);
  }

  &:hover {
    background: #EEF1F3;
  }
}

.filterSourcesArrow {
  margin-left: auto;
  transition-duration: 300ms;
  transition-property: transform;
}

.show {
  visibility: visible;
  z-index: 1001;
}

.rotated {
  transform: rotate(-180deg);
}

.text {
  margin-left: .5rem;
}

.checked {
  background: #EEF1F3;
  color: #1DA1F2;

  & img {
    filter: grayscale(0%);
  }
}

.arrowIcon {
  margin-left: auto;
  outline: none;
}
