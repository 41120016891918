.notiffContainer {
  width: 100%;
  min-height: 50px;
  border-radius: 6px;
  overflow: hidden;

  background: #8bd68b;
  padding: .2rem;
  display: flex;
  outline: none;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  outline: none;
}

.notiffTextPart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  outline: none;
}

.notiffText {
  color: white;
}

.notiffButton {
  border-radius: 100rem;
  padding: .3rem;
  margin: .2rem;
  outline: none;

  &:hover {
    background: #cccccc;
  }

  &>img{
    display: block;
    width: 20px;
    height: 20px;
  }
}
