.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
}

.arrows {
  margin: .2rem;
  padding: .5rem;
  cursor: pointer;
  border-radius: 1000rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  user-select: none;

  &:hover {
    background: white;
  }
  &>img {
    width: 16px;
    height: 16px;
  }
}

.leftDigitStyle {
  text-align: right;
  font-size: .7rem;
  color: #104665;
  width: 1.2rem;
  padding-right: .2rem;
  user-select: none;
}

.itemContainer {
  display: flex;
  align-items: center;
  padding: .2rem;
}
