.timeFilterItemCont {
  padding: .3rem .4rem;
  cursor: pointer;
  outline: none;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #E7F1F8 !important;
  }
}

.notDisplayed {
  display: none;
}

.leftBlock {
  padding: 4px .4rem;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  margin-right: .5rem;
  text-overflow: ellipsis;
}

.rightBlock {
  outline: none;
}
