.ErrCont{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.errPageContentCont {
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 11rem);
  flex: 1;
}

.errContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorContent{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorName {
  font-size: 13.5rem;
  font-weight: 800;
  color: var(--primary-color);
  user-select: none;
  letter-spacing: 3px;
  display: flex;
  line-height: 15rem;
}

.errorDescr{
  font-weight: 300;
  font-size: 2rem;
  font-family: Garuda;
}


.errorLinks {
  margin-top: 2rem;
  display: flex;
  transition: .2s ease-in-out;
  cursor: pointer;

  .backToMainLink {
    margin-left: 5px;
    border-bottom: 1px dashed #1CA5E0;
  }

  &:hover {
    color: #1CA5E0;
  }
}

.errBtn {
  background: var(--primary-color);
  border-radius: var(--border-radius-sm);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin: 25px auto;
  color: white;
  font-size: 1rem;
  width: 20vw;
  height: 5rem;
  border-radius: 3rem;

  &:active {
    background: var(--primary-color-darken);
    box-shadow: 0 0 0 3px var(--primary-color-lighten);
  }
}

