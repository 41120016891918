.overlay{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;

  background: rgba(0,0,0,0);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition-property: all;
  transition-duration: 500ms;
  visibility: hidden;
  z-index: 1000;
  outline: none;
}

.centerPosition {
  align-items: center !important;
}

.show{
  visibility: visible;
  background: rgba(0,0,0,.5);
 }
