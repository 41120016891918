.searchContainerWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  border: solid #e6e6e6 1px;
  border-radius: 6px;
  padding: .2rem;
  padding-left: .5rem;

  & > img {
    flex-shrink: 0;
    width: 20px;
    margin-right: .5rem;
    outline: none;
    user-select: none;
  }
}

.searchContainer {
  margin-bottom: .3rem;
  position: absolute;
  z-index: 2;
  background: white;
  height: 2.2rem;
  left: .6rem;
  top: .4rem;
  right: 1.9rem;

  & input {
    line-height: 1;
    font-size: .9rem;
    color: #104665;
    border: none;
    width: 100%;
    outline: none;
  }
}

.cancelButton {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  outline: none;
  user-select: none;
}

.inputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
