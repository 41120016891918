.searchComponentCont {
  user-select: none;
  display: flex;
  align-items: center;
  background: #EEF1F3;
  overflow: hidden;
  border-radius: var(--border-radius-sm);
  flex-shrink: 0;
  outline: none;
  box-shadow: 0 0 0 1px #EEEEF3;
}

.searchComponentInput {
  font-size: .9rem;
  font-weight: 500;
  color: var(--dark-color);
  padding: 0 .2rem;
  border: none;
  outline: none;
  background: none;
  width: 100%;

}

.searchComponentCancelBut {
  cursor: pointer;
  padding: 1rem .5rem;
  outline: none;

  &:hover {
    opacity: .6;
  }

  & > img {
    display: block;
  }
}

.searchComponentFindBut {
  cursor: pointer;
  padding: 0 .7rem;
  outline: none;
  border-radius: var(--border-radius-sm);

  &:hover {
    opacity: .6;
  }

  & > img {
    display: block;
  }
}

.searchComponentInputCont {
  width: 100%;
  cursor: text;
}

.searchComponentHiddenPart {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
}

.outline {
  box-shadow: 0 0 0 3px var(--primary-color-lighten);
}

.hidden {
  max-width: 0;
}

.hideCross {
  visibility: hidden;
}
