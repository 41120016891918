@import 'modules/variables';
@import 'modules/bootstrap-grid';
@import '/node_modules/react-notifications-component/dist/theme.css';

// global assets
body {
  font-family: 'Mukta';
  margin: 0;
  overflow-y: scroll;

  &.modal-open {
    overflow: hidden;
    padding-right: 15px;
  }
}
.sWBLikeButton {
  visibility: hidden;
  font-weight: 500;
  background: #000;
  opacity: .7;
  border-radius: var(--border-radius-sm);
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  display: flex;
  align-items: center;
  font-size: .7rem;
  color: #fff;
  line-height: 1;

  & img {
    padding-right: 7px;
  }
}