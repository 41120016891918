.sBCont {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.sBlockMd {
  width: 45%;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.sBlockFrameMd {
  width: 100%;
}

.sBlockStringsMd {
  width: 100%;
  margin-top: 1rem;
}

.sBlockMdWide {
  width: 15% !important;
  margin-bottom: .8rem;
}
