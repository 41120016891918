.sWCont {
  margin: 1rem 0;
  min-height: 600px;
}

.sWPart {
  height: 100%;
  align-content: space-between;
}

.sWTopPanel {
  display: flex;
  align-items: center;
  padding: .75rem 1.5rem;
  background: #f6f7f8;
  border-radius: var(--border-radius-sm);
  position: relative;
  user-select: none;
}

.sWFilterButton {
  margin-left: auto;
  padding: 1rem 1.8rem;
  font-size: .9rem;
  line-height: 0;
}

.sWSocialName {
  font-size: 1.5rem;
  color: var(--facebook-color);
  line-height: 1;
  margin-left: 6px;
}

.sWBottomPanel {
  display: flex;
  align-items: center;
  user-select: none;
}

.sWSeeAllLink {
  margin: 0 2rem;
  text-decoration: none;
  color: var(--primary-color);
  flex-shrink: 0;
}

.sWMBlock {
  padding: 1rem 1rem 1.4rem 1rem;
}

.sWDivider {
  border-bottom: 1px solid #383838;
  width: 100%;
  opacity: .1;
}

.sWFilter {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #fff;
  border-radius: var(--border-radius-sm);
}


.hidden {
  display: none !important;
}

.empty {
  display: none;
}

.sWEmptyDataCont {
  font-weight: 700;
  color: lightgray;
  margin-left: 1rem;
  font-size: 1rem
}

.sWParserUpdaterCont {
  margin-left: auto;
}

.leftImgLink {
  display: flex;
  text-decoration: none;

  > img {
    height: 20px;
  }
}

.toLeft {
  margin-left: auto;
}

.navigationWrapper {
  display: flex;
  padding-left: 20px;
}

.navigationBtn {
  height: 36px;
  width: 36px;
  background: #F3F5F7;
  border: 1px solid rgba(16, 70, 101, 0.1);
  border-radius: 8px;
  cursor: pointer;

  img {
    padding: 7px 0;
  }

  &:hover {
    opacity: 0.6;
  }
}

.disabledBtn {
  opacity: 0.2;
  cursor: default;
  pointer-events: none;
}

.navigationSearchBtn {
  display: flex;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  height: 36px;
  width: 200px;
  background: #fff;
  border: 1px solid rgba(16, 70, 101, 0.1);
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  padding: 4px 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #3A3A3A;
  margin: 0 3px;

  &:hover {
    opacity: 0.6;
  }
}

.navigationSearchText {
  width: 158px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sWTopPanelLeft {
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.navigationDropdown {
  z-index: 2;
  position: absolute;
  top: 37px;
  left: -20px;
  width: 100%;
  background: var(--white-background-color);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
  padding: 18px 32px;
}

.searchContainer {
  position: relative;
  left: unset;
  top: unset;
  right: unset;
  width: 97%;
  margin-bottom: 21px;
}

.sourceTagWrapper {
  display: flex;
  gap: 11px 11px;
  flex-wrap: wrap;
}

.sourceTag {
  background: var(--source-tag-background);
  border-radius: 60px;
  height: 36px;
  display: flex;
  align-items: center;
  align-content: space-between;
  padding: 4px 22px 4px 11px;
  line-height: 27px;
  font-size: 16px;
  color: var(--source-tag-color);
  cursor: pointer;
}

.sourceTagActive {
  background: var(--source-tag-background-selected);
}

.sourceTagIcon {
  flex-shrink: 0;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-color-lighten);

  & img {
    width: 17px;
    height: 17px;
  }
}

.sourceTagName {
  width: 100%;
  padding-left: 11px;
}
