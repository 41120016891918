.tfcCont {
  flex-shrink: 0;
  width: 140px;
  user-select: none;
  color: var(--dark-color);
  position: relative;
  outline: none;

}

.tfcRect {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid lightgray;
  border-radius: var(--border-radius-sm);
  z-index: 2;
  left: 0;
  top: calc(100% + .8rem);
  visibility: hidden;
  padding: .5rem;
  font-size: .85rem;

  & > div:nth-child(2n) {
    background: #EfF0F2;
  }
}

.tfcMainButton {
  border: lightgray 1px solid;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0;
  cursor: pointer;
  background: #F3F5F7;
  font-size: .85rem;
  padding: .7rem .9rem;
  outline: none;
  opacity: .99;
  position: relative;
  width: 100%;
  height: 2.8rem;
}

.show {
  visibility: visible;
  z-index: 1001;
}

.highLayer {
  z-index: 1001;
  background: #fff;
}

.TFCCont {
  flex-shrink: 0;
  width: 140px;
  user-select: none;
  color: var(--dark-color);
  position: relative;
  outline: none;
}

.TFCRect {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid lightgray;
  border-radius: var(--border-radius-sm);
  z-index: 2;
  left: 0;
  top: calc(100% + .8rem);
  visibility: hidden;
  padding: .5rem;
  font-size: .85rem;
  &>div:nth-child(2n){
    background: #EfF0F2;
  }
}

.TFCMainButton {
  border: lightgray 1px solid;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0;
  cursor: pointer;
  background: #F3F5F7;
  font-size: .85rem;
  padding: .5rem .9rem;
  outline: none;
  opacity: .99;
  position: relative;
  width: 100%;
}

