.mainPageContentCont {
  justify-content: center;
  min-height: calc(100vh - 14rem);

}

.panelCont {
  margin: 1rem 0;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  display: none;
}

.leftBlock {
  display: flex;
  align-items: center;
  text-decoration: none;

  > img {
    display: block;
    width: 30px;
    height: 30px;
  }
  > div {
    font-weight: 600;
    font-size: 25px;
    margin-left: .5rem;
  }
}

.filterPosition{
  margin-left: auto !important;
}

.mpCont {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}





.switchButton{
  margin-left: auto;
  margin-right: 1rem;
  display: flex;
}

.switchPostViewBtn {
  padding: 0;
  margin-left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #FFFFFF;
  border: 2px solid var(--dark-color);
  cursor: pointer;
  opacity: 0.3;

  &> div {
    width: 14px;
    background: var(--dark-color);
    border-radius: 2px;
    margin: 2px auto;
  }

  &:focus {
    outline: none;
  }

}

.switchToBig {
  & > div {
    height: 3px;
  }
}

.switchToSmall {
  & > div {
    height: 2px;
  }
}

.activeBtn {
  opacity: 1;
}

.switchButtonShow{
  visibility: hidden;
}

.hideLogo {
  visibility: hidden;
}

.contStyle {
  justify-content: center;
}

.panelWrapper {
  align-items: center;
}

.rightBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterHide {
  display: none;
}

.panelHide {
  display: initial;
}

.leftBlockWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

