.breadCrumbElement{
 transform: translateY(.4rem);
  margin-left: 4px;
}
.crumbLink{
  //display: inline;
  opacity: 0.5;
  color: #383838;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0.4rem;
  user-select: none;
}


