

.tfcCont {
  flex-shrink: 0;
  width: 180px;
  user-select: none;
  color: var(--dark-color);
  position: relative;
  outline: none;
}

.tfcRect {

  padding: .5rem;
  font-size: .85rem;

  & > div:nth-child(2n) {
    background: #EfF0F2;
  }
}

.show {
  visibility: visible;
  z-index: 1001;
}

.highLayer {
  z-index: 1001;
  background: #fff;
}

.saveButton {
  background: var(--dark-color);
  padding: .3rem 1rem;
  margin-left: .5rem;
  font-size: .9rem;
  line-height: 1.5;
  align-self: stretch;
  flex-shrink: 0;

  &:active {
    background: var(--dark-color-darken);
    box-shadow: 0 0 0 3px var(--dark-color-lighten);
  }
}

.saveButtonDisabled {
  background: lightgray;
  padding: .3rem 1rem;
  margin-left: .5rem;
  font-size: .9rem;
  line-height: 1.5;
  align-self: stretch;

  &:active {
    background: lightgray;
    box-shadow: none;
  }
}

.templateStr {
  margin-right: .5rem;
  color: var(--dark-color);
  font-weight: 500;
  font-size: 1rem;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.tfcLabel {
  white-space: nowrap;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: .5rem;
}

.inputWrapper {
  width: 75%;
  border: lightgray 1px solid;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  background: white;
  font-size: .85rem;
  outline: none;
  position: relative;
  padding: .5rem 1rem;

  &>input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
  }
}

.hidden {
  display: none;
}

.tempCompContainer {
  max-width: 270px;
}

.navLine {
  margin-bottom: .5rem;
  display: flex;
}

.title {
  color: #104564;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  flex-shrink: 0;
  margin-left: 1rem;
}

.itemsCont {

  overflow-y: scroll;
  height: 355px;
}

.itemsContWrapper{
  padding: .6rem;
  padding-right: .3rem;
  width: 100%;
  background: #fff;
  border: 1px solid lightgray;
  border-radius: var(--border-radius-sm);
}

@media (max-width: 1501px) {
  .navLine {
    flex-wrap: wrap;
  }

  .inputWrapper {
    width: 100%;
  }

  .saveButtonDisabled,
  .saveButton {
    width: 100%;
    margin-left: 0;
    margin-top: .5rem;
  }
}
