.navPanelCont {
  justify-content: center;
  user-select: none;
}

.navPanel {
  padding: 1rem 0;
}

.leftBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftBlockLogo {
  color: var(--dark-color);
  font-weight: 800;
  font-size: 3rem;
  padding-right: 1.5rem;
  line-height: 1;
  text-decoration: none;
}

.rightBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.counterTop {
  font-size: .8rem;
  color: rgba(16, 70, 101, 0.5);
}

.counterBottom {
  color: var(--dark-color);
  font-size: .8rem;
}

.counterCont {
  margin-left: .75rem;
  font-weight: 500;
}

.logoutLink {
  flex-shrink: 0;
  color: var(--dark-color);
  font-size: .85rem;
  font-weight: 500;
}

.parsingButton {
  padding: 1rem;
}


.templatesSwitcher {
  margin-right: .5rem
}

.templateStr {
  margin-right: .5rem;
  color: var(--dark-color);
  font-weight: 500;
}

.filtersButton{
  background: #F3F5F7;
  background: #F3F5F7;
  border: 1px solid rgba(16, 70, 101, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  color: #104665;
  font-size: 16px;
  font-weight: 500;
  font-family: Mukta;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.templatesSwitcherHide{
  display: none !important;
}

.divider {
  width: 100%;
  border-bottom: 1px solid var(--color-lightgray);
}

.filterBarButton {
  background: #104564;
  color: white;
  margin-left: auto;
  margin-right: .6rem;
  display: flex;
  padding: .9rem;
  line-height: 1;

  &>span {
    padding-left: .8rem;
  }
  &:active {
    background: #104564;
  }
}
.timeFilterComponentStyle {
  margin-right: .6rem;
}
.filtersStyle {
  display: flex;
}
