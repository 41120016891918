.loginPageMain {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loginPageContainer {
  padding: 50px 100px;
  max-width: 570px !important;
  width: 100%;
  background: var(--secondary-color);
  border-radius: var(--border-radius-sm);
}

.brand {
  line-height: 1;
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  color: var(--dark-color);
}

.content {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  padding-top: 1rem;
  padding-bottom: 34px;
  opacity: .5;
  color: var(--font-color-basic);
}

.contBottom {

}

.footer {
  text-align: center;
  bottom: 0;
  font-family: 'Mukta';
  font-weight: 400;
  opacity: .4;
  padding: 1rem;
  font-size: 13px;
  position: absolute;
}

.loginButton {
  width: 100%;
  padding: .8rem;

  & span {
    padding-left: 10px;
  }
}

.checkingRegistrationStyle {
  color: #1CA5E0;
  font-weight: 700;
  font-size: 20px;
  display: none;
}

.checkingRegistrationShow {
  display: block;
}

.loginPageContainerShow {
  display: none;
}

.errorIndicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1CA5E008;
  color: orange;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.errorIndicatorShow {
  visibility: visible;
}

.errorIndicatorInner {
  padding: 1rem 0;
}
