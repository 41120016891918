.sBCont {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.sBContSm {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.sBlockSm {
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
}

.sBlockFrameSm {
  width: 28%;
  flex-shrink: 0;
}

.sBlockStringsSm {
  width: 60%;
  margin-left: 1rem;
}
