.shareCollectionContainer {
  width: 370px;
  padding-bottom: 183px;

  [aria-label="select options"] {
    height: 190px;

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-color: var(--hover-light-color);
    }
  }
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.parserIconContainer {
  flex-shrink: 0;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-color-lighten);

  & img {
    width: 17px;
    height: 17px;
  }
}

.nameContainer {
  width: 100%;
  padding-left: 10px;
  color: var(--text-primary-color);
}

.checked {}