.squareStyle {
  padding-left: .1rem;
  padding-top: 2.5rem;
  padding-right: .2rem;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
  height: 405px;
  cursor: pointer;
}
