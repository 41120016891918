.triggerButton {
  color: #fff;
  position: absolute;
  background: #104665;
  bottom: 8px;
  right: 8px;
  padding: 4px 12px;
  display: flex;
  border-radius: 4px;
  font-size: 18px;
  line-height: 24px;

  &:hover {
    background-color: var(--primary-color);
  }
}

:global {
  div {
    &:hover > {
      .triggerButtonEmpty {
        visibility: visible;
        opacity: 1;
      }
    }

    .triggerButtonEmpty {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 17px;
  font-size: 18px;
  color: #88A3B2;
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-lightgray);
  }
}