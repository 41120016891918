.sWBouter {
  outline: none;

  & a {
    text-decoration: none !important;
  }
}

.shareCount {
  color: #ccc;
  font-style: italic;
}

.breakLine {
  border-radius: 11px;
  border-bottom: 5px solid #FF4501;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.4rem;
}

.sWBCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;

  &-lg {
    flex-direction: column;
  }

  &-md {
    flex-direction: column;
  }

  &-sm {
    flex-direction: row;
    align-items: center;

  }
}

.sWBFrameCont {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    display: block;
    margin-top: 75%;
  }

  &-lg {
    width: 100%;
  }

  &-md {
    width: 100%;
  }

  &-sm {
    width: 28%;
    align-self: flex-start;
  }
}

.sWBFlink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sWBFrame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sWBText {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none !important;

  &-lg {

    font-size: 1rem;
    margin-top: 1rem;
    width: 100%;

    & .descriptionPart {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: #383838;
      font-weight: 500;
      height: calc(27px * 3);
      -webkit-line-clamp: 3;
      text-decoration: none;
    }
  }

  &-md {
    line-height: 21px;

    font-size: .85rem;
    margin-top: .4rem;
    width: 100%;

    & .descriptionPart {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: #383838;
      font-weight: 500;
      height: calc(21px * 2);
      -webkit-line-clamp: 2;
    }
  }

  &-sm {
    width: 72%;
    line-height: 16px;
    font-size: .75rem;
    padding-left: 1rem;

    & .descriptionPart {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: #383838;
      font-weight: 500;
      height: calc(16px * 2);
      -webkit-line-clamp: 2;
    }
  }

  & a {
    text-decoration: none !important;
  }


}


.sWBLgStyleForCont {
  flex-direction: column;
}

.sWBLgStyleForFrameCont {
  width: 100%;
}

.sWBLgStyleForText {
  line-height: 27px;
  height: calc(27px * 3);
  -webkit-line-clamp: 3;
  font-size: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.sWBMdStyleForCont {
  flex-direction: column;
}

.sWBMdStyleForFrameCont {
  width: 100%;
}

.sWBMdStyleForText {
  line-height: 21px;
  height: calc(21px * 2);
  -webkit-line-clamp: 2;
  font-size: .85rem;
  margin-top: .4rem;
  width: 100%;
}

.sWBSmStyleForCont {
  flex-direction: row;
  align-items: center;
}

.sWBSmStyleForFrameCont {
  width: 28%;
}

.sWBSmStyleForText {
  width: 72%;
  line-height: 21px;
  height: calc(21px * 2);
  -webkit-line-clamp: 2;
  font-size: .85rem;
  padding-left: 1rem;
}

.sWBLikeButton {
  visibility: hidden;
  font-weight: 500;
  background: #000;
  opacity: .7;
  border-radius: var(--border-radius-sm);
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  display: flex;
  align-items: center;
  font-size: .7rem;
  color: #fff;
  line-height: 1;

  & img {
    padding-right: 7px;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  display: none;
}

.trendsStyle {
  display: none;
  color: #1da1f2;
  font-weight: 600;
}

.displayed {
  display: block;
}


.sectionStyle {
  color: rgb(223, 0, 100);
  font-weight: 500;
  font-size: 1rem;
};

.sectionNotVisible {
  display: none;
}
