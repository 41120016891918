.commentsModalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.commentsModalHeader {
  padding: 24px 34px;
  background-color: var(--secondary-color);
  border: 1px solid #E6E6E6;
  display: flex;
  flex: none;
}

.commentsModalBody {
  flex: 1;
  overflow: auto;
  padding: 15px 34px;
}

.commentsModalFooter {
  padding: 16px 34px;
  border-top: 1px solid rgba(#104665, 0.1);
  display: flex;
  align-items: center;
  height: 84px;
}

.writeCommentButton {
  cursor: pointer;
  background: none;
  border: none;
  margin-left: 14px;
  padding: 4px;

  :global {
    img {
      display: block;
    }
  }

  &:hover {
    background-color: var(--color-lightgray);
  }

  &:disabled {
    opacity: 0.7;
  }
}

.textarea {
  height: 100%;
  flex: 1;
  resize: none;
  border: 1px solid rgba(16, 70, 101, 0.1);
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
}

.leftHeaderColumn {
  width: 100px;
  flex: none;
  margin-right: 16px;

}

.rightHeaderColumn {
  flex: 1;
  overflow: hidden;
}

.commentsWindowImage {
  max-width: 100px;
  max-height: 75px;
  border-radius: var(--border-radius-sm);
}

.modalWidgetText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.errorMessage {
  position: absolute;
  right: 0;
  background: #d48f8f;
  padding: 15px;
  bottom: 0;
  left: 0;
  height: 83px;
}
