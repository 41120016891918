.trendsItemCont {
  padding: .2rem 1.2rem;
  background: #F3F5F7;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  user-select: none;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}

.hashtag {
  color: #1DA1F2;
  font-size: 28px;
  font-weight: 700;
  margin-right: .8rem;
}
