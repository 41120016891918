.selectedFiltersItemCont {
  border: var(--secondary-color) 1px solid;
  border-radius: 1000rem;
  padding: .4rem .6rem;
  line-height: 1;
  margin: 0 .2rem;
  margin-bottom: .4rem;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: .875rem;
  font-weight: 500;
  color: var(--dark-color);
  background: #EEF1F3;
  cursor: pointer;

  &:hover {
    background: #e4e6e7;
  }
}

.badgeIconStyle {
  cursor: pointer;
  outline: none !important;
  display: flex;
  align-items: center;
}

.mainWrapper {
  display: flex;
  align-items: center;
  position: relative;
  outline: none !important;

  &:hover [data-hoverelem=true]{
    display: block;
  }
}

.hoverElem {
  position: absolute;
  left: -10px;
  top: -35px;
  border-radius: 5px;
  background: #babcbe;
  color: white;
  padding: .3rem;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
}

.badgeTextStyle {
  margin-left: .3rem;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--dark-color);
}
