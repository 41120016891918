.btn {
  width: 100%;
  height: 38px;
  padding: 0 13px;
  cursor: pointer;
  outline: none;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  color: #104665;

  &:hover {
    background: #F1F2F5 !important;
  }

  &:active {
    background: none;
    box-shadow: none;
  }
}

.btnSelected {
  background: #F1F2F5 !important;
}

.buttonText {
  color: #1B3E5A;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.nameContainer {
  width: 100%;
  padding-left: 13px;
  text-align: left;
  color: var(--text-primary-color);
}

.parserIconContainer {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-color-lighten);

  & img {
    width: 20px;
    height: 20px;
  }
}