.container {
  position: fixed;
  top: 5px;
  background: #c85e58;
  max-width: 50%;
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-sm);
  padding: 1rem;
  z-index: 2;
}

.cancelIcon {
  align-self: flex-end;
  cursor: pointer;

  & img{
    display: block;
  }
}

.textCont {
  padding-top: .5rem;
  color: white;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.hidden {
  visibility: hidden;
}
