.sBCont {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.sBlockLg {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
}

.sBlockFrameLg {
  width: 100%;
}

.sBlockStringsLg {
  width: 100%;
  margin-top: 1.5rem;
}

.blockMargin {
  margin: 1rem 0;
}

