.selectedFilters {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;

  width: 100%;
  flex-wrap: wrap;
}

.selectedFiltersHide {
  display: none !important;
}
